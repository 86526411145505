import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/Layout';
import { Blogs } from '../components';
import { blogsData } from '../data';

const AllBlogsPage = ({ location }) => {
	const [ active, setActive ] = useState('');
	useEffect(() => {
		setActive(location.pathname.concat(location.hash));
	}, []);
	useEffect(
		() => {
			setActive(location.pathname.concat(location.hash));
		},
		[ location.href ]
	);
	// const data = useStaticQuery(graphql`
	// 	query {
	// 		allContentfulBlogPost {
	// 			edges {
	// 				node {
	// 					title
	// 					slug
	// 					imageUrl {
	// 						file {
	// 							url
	// 							fileName
	// 						}
	// 					}
	// 					publishedDate(formatString: "MMM Do, YYYY")
	// 				}
	// 			}
	// 		}
	// 	}
	// `);
	// const blogsData = data.allContentfulBlogPost.edges.map(({ node: { slug, title, imageUrl, publishedDate } }) => ({
	// 	title,
	// 	imageURL: imageUrl?.file?.url,
	// 	imageName: imageUrl?.file?.fileName,
	// 	blogURL: `/blogs/${slug}`,
	// 	publishedDate,
	// 	body: ''
	// }));
	return (
		<Layout setActive={setActive} active={active} location={location}>
			<Blogs blogsData={blogsData} active={active} setActive={setActive} location={location} />
		</Layout>
	);
};

export default AllBlogsPage;
